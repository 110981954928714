import logoImg from "../../../512-noBG.png"
const  Logo = ({width,height}) => {
    return (
        <img src={logoImg} alt="logo" width={width} height={height} />
            
            
       
    )
}

export default Logo